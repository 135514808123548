import { render, staticRenderFns } from "./LocalCollectInvoice.vue?vue&type=template&id=967f339c&scoped=true&"
import script from "./LocalCollectInvoice.vue?vue&type=script&lang=js&"
export * from "./LocalCollectInvoice.vue?vue&type=script&lang=js&"
import style0 from "./LocalCollectInvoice.vue?vue&type=style&index=0&id=967f339c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "967f339c",
  null
  
)

export default component.exports